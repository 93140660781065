<!--  -->
<template>
  <div class="Details">
    <!-- 面包屑导航 -->
    <el-card>
      <el-form :model="form">
        <el-row>
          <el-col :span="8">
            <el-form-item label="选择班制" :label-width="formLabelWidth">
              <el-cascader
                placeholder="地区/单位/班制"
                :options="optionsAll"
                :props="optionProps"
                v-model="form.word"
                filterable
                clearable
              >
              </el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="用户名" :label-width="formLabelWidth">
              <el-input
                v-model="form.username"
                autocomplete="off"
                placeholder="选择用户名"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="选择岗位" :label-width="formLabelWidth">
              <el-select
                v-model="form.post"
                multiple
                style="width: 100%"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="招聘渠道" :label-width="formLabelWidth">
              <el-radio-group v-model="form.channel" size="small">
                <el-radio-button
                  v-for="item in radiolist2"
                  :label="item.name"
                  :key="item.id"
                ></el-radio-button>
              </el-radio-group>
              <el-input
                v-model="form.channel"
                autocomplete="off"
                v-if="
                  form.channel != '网络' &&
                    form.channel != '内推' &&
                    form.channel != '外协'
                "
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8"> </el-col>
          <el-col :span="8"> </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="用工形式" :label-width="formLabelWidth">
              <el-radio-group v-model="form.employment" size="small">
                <el-radio-button
                  v-for="item in options4"
                  :label="item.id"
                  :key="item.id"
                  >{{ item.name }}</el-radio-button
                >
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="合同起止时间" :label-width="formLabelWidth">
              <el-date-picker
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="form.ht_add_time"
                type="datetime"
                placeholder="开始时间"
              >
              </el-date-picker>
              <el-date-picker
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="form.ht_del_time"
                type="datetime"
                placeholder="结束时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="合同签订年限" :label-width="formLabelWidth">
          <el-radio-group
            v-model="form.ht_limit"
            size="small"
            @change="setTime"
          >
            <el-radio-button
              v-for="item in radiolist"
              :label="item.id"
              :key="item.id"
              >{{ item.name }}</el-radio-button
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="试用起止时间" :label-width="formLabelWidth">
          <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="form.add_probation"
            type="datetime"
            placeholder="开始时间"
          >
          </el-date-picker>
          <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="form.del_probation"
            type="datetime"
            placeholder="开始时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="试用期月限" :label-width="formLabelWidth">
          <el-radio-group
            v-model="form.ht_month"
            size="small"
            @change="setMonth"
          >
            <el-radio-button
              v-for="item in radioMonth"
              :label="item.id"
              :key="item.id"
              >{{ item.name }}</el-radio-button
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="合同签订次数" :label-width="formLabelWidth">
          <el-radio-group v-model="form.ht_c_time" size="small">
            <el-radio-button
              v-for="item in radiolist3"
              :label="item.name"
              :key="item.name"
              >{{ item.name }}</el-radio-button
            >
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="btn">
        <el-button type="primary" class="addbtn" @click="addbtn()"
          >确定添加</el-button
        >
      </div>
    </el-card>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

import { getOptions, getRegion, getHrAdd, getSection } from '@/api/user'
import { number } from 'echarts'

//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      allname: [],
      optionsAll: [],
      optionProps: {
        checkStrictly: false,
        value: 'id',
        label: 'name',
        children: 'sub',
      },
      //添加表单
      form: {
        word: [],
        ht_add_time: '',
        ht_del_time: '',
        ht_month: '',
        ht_limit: '',
        probation: '',
        ht_c_time: '',
        channel: '网络',
        username: '',
        post: [],
        employment: '',
        add_probation: '',
        del_probation: '',
      },
      //form文本长度
      formLabelWidth: '130px',
      //分类选择options
      options: [],
      cities: [],
      radiolist: [
        {
          id: 1,
          name: '一年',
        },
        {
          id: 2,
          name: '两年',
        },
        {
          id: 3,
          name: '三年',
        },
        {
          id: 0,
          name: '无固定',
        },
      ],
      radioMonth: [
        {
          id: 1,
          name: '一月',
        },
        {
          id: 2,
          name: '两月',
        },
        {
          id: 3,
          name: '三月',
        },
        {
          id: 6,
          name: '六月',
        },
      ],
      getNav: [],
      type: 0,
      radiolist2: [
        {
          id: '1',
          name: '网络',
        },
        {
          id: '2',
          name: '内推',
        },
        {
          id: '3',
          name: '外协',
        },
        {
          id: '4',
          name: '其他',
        },
      ],
      radiolist3: [
        {
          id: 1,
          name: 1,
        },
        {
          id: 2,
          name: 2,
        },
        {
          id: 3,
          name: 3,
        },
      ],
      work_shift: Number,
      options3: [],
      options4: [],
      options5: [],
      options6: [],
      type2: 0,
      query: null,
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    setTime() {
      let ht_add_time = this.form.ht_add_time
      let num = this.form.ht_limit
      if (ht_add_time && num) {
        let newTime =
          Number(ht_add_time.slice(0, 4)) + num + ht_add_time.slice(4)
        console.log(newTime)

        this.form.ht_del_time = newTime
      }
    },
    setMonth() {
      if (!this.form.add_probation) {
        return
      }
      let add_probation = this.form.add_probation
      console.log(add_probation)
      let arrlist = add_probation.split('-')
      console.log(this.form.ht_month)
      arrlist[1] = Number(arrlist[1]) + this.form.ht_month
      if (arrlist[1] > 12) {
        arrlist[0] = Number(arrlist[0]) + 1
        arrlist[1] = arrlist[1] - 12
      }
      console.log(arrlist.join('-'))
      let info = +new Date(arrlist.join('-'))
      console.log(info)
      this.form.del_probation = this.formatDate(info)
    },
    //最终提交
    addbtn() {
      this.setCoupon()
    },
    //提交信息
    async setCoupon() {
      let info = await getHrAdd(this.form)
      if (info.data.code == 0) {
        this.$message({
          message: '添加成功',
          type: 'success',
        })
        this.form.username += 1
        this.form = {
          word: [],
          ht_add_time: '2023-12-15 00:00:00',
          ht_del_time: '',
          ht_month: '',
          ht_limit: '',
          probation: '',
          ht_c_time: '',
          channel: '网络',
          username: '',
          post: [],
          employment: '',
          add_probation: '',
          del_probation: '',
        }
        this.$router.push({
          path: '/userlist',
        })
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
        return
      }
    },
    formatDate(info) {
      let date = new Date(info)
      let YY = date.getFullYear() + '-'
      let MM =
        (date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1) + '-'
      let DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      let hh =
        (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      let mm =
        (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
        ':'
      let ss =
        date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      return YY + MM + DD + ' ' + hh + mm + ss
    },
    async setpOtions() {
      this.getNav = this.$getNavn('/userlist')
      let info = ''
      //用工形式
      info = await getSection()
      let info2 = await getOptions()
      let info5 = await getRegion()
      this.options = info2.data.data
      this.options4 = info.data.data
      this.optionsAll = info5.data.data
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {
    this.setpOtions()
  }, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.addbtn {
  width: 300px;
}
</style>
